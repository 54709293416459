import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import SectorPage from '../../components/sectorPage'

const BeautyPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/beaute" type="sector">
      <SEO 
        title="Classement des meilleurs sites du secteur de la beauté - 2021"
        description="Quels sont les meilleurs sites du secteur de la beauté de l'Observatoire de l'impact positif ? Découvrez les résultats de L’Occitane, Yves Rocher, Clarins, Nuxe, et L’Oréal."
        path="/2021/beaute"
        year="2021" />
      
      <Breadcrumb url="/2021/beaute" label="Beauté" year="2021" />

      <SectorPage slug="beauty" type="sector" year="2021" />
    </Layout>
  )
  }

export default BeautyPage

